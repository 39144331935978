<template>
  <div class="case-conent">
    <div class="hd">知识内容评测</div>
    <!--学历案日志列表-->
    <div class="table-content-search">
      <div class="search">
        <div class="fromItem">
          <el-select v-model="ruleForm.subjectId" @change="subjectChange" @clear="subjectClear()" clearable placeholder="学科选择">
            <el-option
                v-for="item in SubjectList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="fromItem">
          <el-input placeholder="请输入图书名称" clearable class="input-with-select" style="width: 300px;" v-model="ruleForm.bookName" @change="nameChange()" @clear="nameClear()"></el-input>
        </div>
        <div class="fromItem">
          <el-button type="primary" size="small" @click="ObjBtn()"
                     style="text-align: left;"><i class="icon-daoru1-copy"></i> 搜索
          </el-button>
        </div>
      </div>
      <div class="table">
        <span v-if="code==200">
          <el-table
              border
              :header-cell-style="{background:'#D1EEFE',color:'#333'}"
              :data="listData"
          >
          <el-table-column prop="sort" label="序号"  header-align="center" width="50"></el-table-column>
          <el-table-column prop="bookName" label="图书名称" header-align="center"></el-table-column>
          <el-table-column prop="folderName" label="课时名称" header-align="center"></el-table-column>
          <el-table-column prop="exerciseName" label="练习名称" header-align="center"></el-table-column>
          <el-table-column prop="subjectName" label="学科" header-align="center"></el-table-column>
          <el-table-column prop="createTime" label="作业提交时间" header-align="center"></el-table-column>
          <el-table-column prop="score" width="80" label="成绩" header-align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">{{scope.row.score}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="contents" label="知识内容总数" header-align="center"></el-table-column>
          <el-table-column prop="qus" width="80" label="总题数" header-align="center">
            <template slot-scope="scope">
              <div class="dat1">{{scope.row.qus}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="errors" label="未掌握题数" header-align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">{{scope.row.errors}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="基本掌握题数" header-align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">{{scope.row.rights}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="掌握较好题数" header-align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">{{scope.row.goods}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" header-align="center">
            <template #default="scope">
              <div v-if="scope.row.status==1">
                <div> <el-button @click="toKnowledgeMasteryDetail(scope)" icon="el-icon-tickets" type="primary" size="mini" plain>知识内容掌握情况</el-button>
</div>
                <div class="btnBOx">
                                <el-button @click="toSunit(scope)" icon="el-icon-search" type="primary" size="mini" plain>批改结果</el-button>

                </div>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          </el-table>
        </span>
        <span v-else>
              <div class="kong">
                <img src="../../assets/images/kong.png">
                <p>暂无查询结果！</p>
              </div>
        </span>
      </div>
      <div class="page">
        <div class="pag-box" v-if="code==200">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import {ParContentExerciseListObj} from '@/api/parent'
export default {
  name: '',
  data() {
    return {
      clickFlag:null,
      code:null,
      ruleForm:{
        subjectId:null,
        bookName:'',
      },
      listData:[],
      pageSize:null,
      pageCurrent:null,
      total:null,
    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.par = this.$route.query.par;
    this.subjectObj()
    this.listObj()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  methods: {
    subjectObj() {
      this.ruleForm.subjectId = this.SubjectList[0].id
    },
    //查询筛选的学科列表
    listObj() {
      let params = {
        subjectId: this.ruleForm.subjectId,//学科
        bookName: this.ruleForm.bookName,//图书，
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      ParContentExerciseListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.listData = res.data.records
          this.code = res.code;
          this.pageSize = res.data.size;
          this.currentPage = res.data.current;
          this.total = res.data.total
        } else if(res.code==209) {
          this.code = res.code;
        }
      })
    },
    ObjBtn() {
      this.listObj()
    },
    //充值订单列表分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.listObj();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.listObj();
    },
    // 学科
    subjectChange($event){
      this.ruleForm.subjectId = $event
      this.listObj()
    },
    // 清除学科
    subjectClear() {
      this.ruleForm.subjectId = ''
      this.listObj()
    },


    // 名称
    nameChange(){
      sessionStorage.setItem('KnowledgeMasteryName',this.ruleForm.bookName)
    },
    // 清除状态
    statusClear() {
      this.ruleForm.bookName = ''
      //sessionStorage.setItem('bookSubjectStatus',this.bookSubject.status = '')
      sessionStorage.removeItem('KnowledgeMasteryName');
      // this.getList()
    },

    // 知识内容掌握情况详情
    toKnowledgeMasteryDetail(scope) {
      this.$router.push({
        path: "/mainb/KnowledgeMasteryDetail",
        query: {
          EducationCase: this.EducationCase,
          clickFlag: 2,
          testPaperId: scope.row.testPaperId,
          par:1
        }
      });
    },
    // 去批改结果
    toSunit(scope) {
      this.$router.push({
        path: "/mainb/parunit",
        query: {
          EducationCase: this.EducationCase,
          clickFlag: 2,
          testPaperId: scope.row.testPaperId,
          par:1,
          bookName:this.bookName,
        }
      });
    },


  },
  computed:{
    ...mapState([
      'SubjectList',
    ]),
  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;
  .dat1 {
    width: 60px;
  }
  .btnBOx {
    width: 100%;
    margin-top: 10px;
  }
  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
  }

  .table-content-search {
    width: 96%;
    margin: 10px auto;
    .search {
      width: 100%;
      height: 40px;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      .fromItem {
        margin-left: 10px;

      }
    }
    .table {
      height: 60%;
    }
    .page {
      height: 40px;
    }
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
