//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

// 查询家长信息：
export function getParFamilyInfoObj(obj) {
    return request({
        url: `/study/familys/queryFamilyInfo/${obj.userName}`,    // url = base url + request url
        method: 'get',

    })
}
// 查询学生信息
export function getParChildrenObj(obj) {
    return request({
        url: `/study/familys/children/${obj.studentMobileNumber}`,    // url = base url + request url
        method: 'get',

    })
}
// 查询作业考试完成列表：
export function queryExerciseListObj(obj) {
    return request({
        url: '/study/familys/queryExerciseList',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询单元卷练习考试完成列表：
export function queryUnitExerciseListObj(obj) {
    return request({
        url: '/study/familys/queryUnitExerciseList',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询成绩统计列表：
export function ParqueryScoreListObj(obj) {
    return request({
        url: '/study/familys/queryScoreList',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询知识点撑握情况列表：
export function ParContentExerciseListObj(obj) {
    return request({
        url: '/study/familys/queryContentExerciseList',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询知识内容掌握情况详情列表
export function getParContentKnowledgeListObj(obj,paperId) {
    return request({
        url: `/study/familys/queryContentKnowledgeList/${paperId}`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询知识内容批改结果
export function getParContentCorrectsObj(paperId) {
    return request({
        url: `/study/familys/queryContentCorrects/${paperId}`,    // url = base url + request url
        method: 'get',
    })
}
// 成长轨迹图
export function getParstackedLineObj(obj) {
    return request({
        url: `/study/familys/stackedLine`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询充值记录列表
export function getParqueryPayListObj(obj) {
    return request({
        url: `/study/familys/queryPayList`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询充值详情
export function getParPayDetailObj(obj) {
    return request({
        url: `/study/familys/queryPayDetail/${obj.orderNo}`,    // url = base url + request url
        method: 'get',
    })
}
// 查询课时购买记录列表
export function getParBuyListObj(obj) {
    return request({
        url: `/study/familys/queryBuyList`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询整本购买记录列表
export function getParBookBuyListObj(obj) {
    return request({
        url: `/study/familys/queryBookBuyList`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询知识内容评测批改结果：
export function tKnowContentCorrectsObj(obj) {
    return request({
        url: `/study/familys/queryContentCorrects/${obj.paperId}`,    // url = base url + request url
        method: 'get',
        data: obj,
    })
}